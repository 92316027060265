@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --tileSize: 2rem;
}

.tile {
  grid-column-start: span 2;
  grid-row-start: span 2;
}
